import React, { useCallback, useMemo } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useState, useEffect } from "react";

import {
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table";
import Modal from "react-bootstrap/Modal";
import Airtable from "airtable";
import Filters from "./Filters";
import moment from "moment";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import DesktopLogo from "../images/mdi_sort.svg";
import { BsThreeDots } from "react-icons/bs";
import { TbSortAscending, TbSortDescending } from "react-icons/tb";
import {
	MdDelete,
	MdEdit,
	MdOutlineClear,
	MdVideoCameraFront,
} from "react-icons/md";
import { Link } from "gatsby";
import LeftNavbar from "./left-navbar";
import AccountBox from "./account-box";
import Skeleton from "react-loading-skeleton";
import { v4 as uuidv4 } from "uuid";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { FaCopy, FaEye } from "react-icons/fa";
import { IoDuplicateSharp } from "react-icons/io5";

const TaskTable = ({ user, roles }) => {
	const [copied, setCopied] = useState(false);
	const [loadForm, setLoadForm] = useState(false);
	const [disableLoad, setDisableLoad] = useState(false);
	const [data, setData] = useState([]);
	const [dataRecords, setDataRecords] = useState([]);
	const [show, setShow] = useState(false);
	const [selectedEventId, setSelectedEventId] = useState("");
	const [selectEventLanguages, setSelectEventLanguages] = useState([]);
	const [selectedEvent, setSelectedEvent] = useState("");
	const [filtering, setFiltering] = useState("");

	let isAdmin = roles.includes("Admin");
	let isManager = roles.includes("Manager");

	const handleClose = useCallback(() => setShow(false), []);
	const handleShow = useCallback(() => setShow(true), []);

	useEffect(() => {
		const temp = data.find(
			(element) => element.uniqueEventId === selectedEventId
		);
		// console.log(temp);
		setSelectedEvent(temp);
		setSelectEventLanguages(JSON.parse(temp?.languages || "[]"));
		setCopied(false);
	}, [data, selectedEventId]);
	useEffect(() => {
		if (disableLoad === true) {
			setTimeout(() => {
				setLoadForm(true);
			}, 500);
		}
	}, [disableLoad]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch("/.netlify/functions/event-table-fetch", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						"x-api-key": process.env.GATSBY_EVENTTABLE_KEY_1,
					},
					body: JSON.stringify({
						isAdmin,
						isManager,
						userEmail: user?.email,
					}),
				});

				if (!response.ok) {
					throw new Error("Network response was not ok");
				}

				const records = await response.json();
				const sortedRecords = records.sort(
					(a, b) =>
						new Date(b.fields.dateCreated) - new Date(a.fields.dateCreated)
				);

				setData(sortedRecords.map((record) => record.fields));
				setDataRecords(records);

				setDisableLoad(true);
			} catch (err) {
				// console.error(err);
				alert(
					"Could not retrieve data from Airtable. Please wait 30 seconds and refresh the page"
				);
			}
		};

		fetchData();
	}, []);

	const columns = [
		{
			accessorKey: "view",
			header: "View",
			size: 60,
			cell: (props) => (
				<div>
					<BsThreeDots
						onClick={() => {
							setSelectedEventId(props.row.original.uniqueEventId);

							handleShow();
						}}
						style={{ cursor: "pointer" }}
					/>
				</div>
			),
			enableGlobalFilter: false,
			enableSorting: false,
		},
		{
			enableGlobalFilter: true,
			accessorKey: "code",
			header: "Code",
			size: 60,

			cell: (props) => (
				<p style={{ fontSize: "90%" }} className="pb-0 mb-0">
					{props.getValue()}
				</p>
			),
		},
		{
			enableGlobalFilter: false,
			accessorKey: "date",
			header: "Date",
			cell: (props) => (
				<p style={{ fontSize: "90%" }} className="pb-0 mb-0">
					{props.getValue()}
				</p>
			),
		},
		{
			enableGlobalFilter: true,
			accessorKey: "event",
			header: "Event name",
			cell: (props) => (
				<p style={{ fontSize: "90%" }} className="pb-0 mb-0">
					{props
						.getValue()
						.split(" ")
						.map(
							(word) =>
								word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
						)
						.join(" ")}
				</p>
			),
		},
		{
			enableGlobalFilter: true,
			size: 80,
			accessorKey: "dm",
			header: "DM name",
			cell: (props) => (
				<p style={{ fontSize: "90%" }} className="pb-0 mb-0">
					{props.getValue()}
				</p>
			),
		},
		{
			enableGlobalFilter: true,
			accessorKey: "venue",
			header: "Venue",
			cell: (props) => (
				<p style={{ fontSize: "90%" }} className="pb-0 mb-0">
					{props.getValue()}
				</p>
			),
		},
		{
			enableGlobalFilter: true,
			size: 80,
			accessorKey: "country",
			header: "Country",
			cell: (props) => (
				<p style={{ fontSize: "90%" }} className="pb-0 mb-0">
					{props.getValue()}
				</p>
			),
		},
	];

	const table = useReactTable({
		data,
		columns,
		state: {
			globalFilter: filtering,
		},

		onGlobalFilterChange: setFiltering,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getSortedRowModel: getSortedRowModel(),
		columnResizeMode: "onChange",
		meta: {
			updateData: (rowIndex, columnId, value) => {
				setData((prev) => {
					return prev.map((row, index) =>
						index === rowIndex ? { ...row, [columnId]: value } : row
					);
				});
			},
			deleteData: (code) => {
				setData((prev) => prev.filter((item) => item.uniqueEventId !== code));
			},
		},
	});

	const handleDelete = async (event, eventCode) => {
		if (window.confirm("Are you sure you want to delete?")) {
			const temp = dataRecords.find(
				(element) => element.fields.uniqueEventId === event.uniqueEventId
			);

			try {
				const response = await fetch("/.netlify/functions/event-table-delete", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						"x-api-key": process.env.GATSBY_EVENTTABLE_KEY_2,
					},
					body: JSON.stringify({ eventId: temp.id }),
				});

				if (!response.ok) {
					throw new Error("Network response was not ok");
				}

				// console.log("Deleted record", temp.id);
				table.options.meta?.deleteData(event.uniqueEventId);
				handleClose();
			} catch (err) {
				alert(
					"Could not update data in Airtable. Please wait 30 seconds and try again. Error: " +
						err.message
				);
				// console.error(err);
			}
		}
	};

	const renderSortIcon = (column) => {
		const isSorted = column.getIsSorted();
		const canSort = column.getCanSort();

		if (!canSort) {
			return null;
		}

		if (!isSorted) {
			return (
				<img
					src={DesktopLogo}
					alt="Sort"
					style={{ width: "15px", cursor: "pointer" }}
					onClick={column.getToggleSortingHandler()}
					className="ms-2"
				/>
			);
		}

		if (isSorted === "asc") {
			return (
				<TbSortAscending
					onClick={column.getToggleSortingHandler()}
					style={{ width: "15px", cursor: "pointer", height: "15px" }}
					className="ms-2"
				/>
			);
		}

		if (isSorted === "desc") {
			return (
				<TbSortDescending
					onClick={column.getToggleSortingHandler()}
					style={{ width: "15px", cursor: "pointer", height: "15px" }}
					className="ms-2"
				/>
			);
		}

		return null;
	};

	const renderTableHeader = () => {
		return table.getHeaderGroups().map((headerGroup) => (
			<thead key={headerGroup.id}>
				<tr>
					{headerGroup.headers.map((header) => (
						<th
							key={header.id}
							className="text-center ps-3 pt-3 fw-bold pb-3"
							style={{
								width: header.getSize(),
								backgroundColor: "#FBFBFB",
							}}
						>
							{header.column.columnDef.header}
							{renderSortIcon(header.column)}
						</th>
					))}
				</tr>
			</thead>
		));
	};

	const renderTableBody = () => {
		return table.getRowModel().rows.map((row) => (
			<>
				<tr key={row.id} className="text-center">
					{row.getVisibleCells().map((cell) => (
						<td
							key={cell.id}
							className="py-3"
							style={{ width: cell.column.getSize() }}
						>
							{flexRender(cell.column.columnDef.cell, cell.getContext())}
						</td>
					))}
				</tr>
			</>
		));
	};

	return (
		<section>
			<Modal
				style={{ zIndex: 99999 }}
				size="xl"
				show={show}
				onHide={handleClose}
				centered
			>
				<Modal.Body
					style={{ borderRadius: "8px" }}
					className="bg-white px-4"
					closeButton
				>
					<div className="   position-relative">
						<div className="position-absolute  end-0 top-0  pt-2">
							<MdOutlineClear
								style={{ cursor: "pointer" }}
								className="fs-4 text-black"
								onClick={handleClose}
							/>
						</div>
						<div className="pb-5 pt-3">
							<Row className="align-items-center">
								<Col lg={2}>
									<h2 className="fs-4 mb-0 pb-0">Event details</h2>
								</Col>
								<Col lg={9} className=" text-center">
									<Button
										className="px-4 me-3 text-white"
										variant="dark-bg"
										onClick={async () => {
											await navigator.clipboard.writeText(
												`https://register.richmondevents.com/invite?code=${selectedEvent?.code}`
											);

											setCopied(true);
										}}
									>
										{copied ? "Copied" : "Copy Url"} <FaCopy className="ms-2" />
									</Button>
									<Button
										className="px-3 me-3 "
										variant="outline-dark-bg"
										as={Link}
										to={`/admin/edit-invite?code=${selectedEventId}`}
									>
										Edit <MdEdit className="ms-2" />
									</Button>

									<Button
										className="px-3 me-3 "
										variant="outline-dark-bg"
										as={Link}
										to={`/admin/event-attendees?code=${selectedEventId}`}
									>
										Attendees <FaEye className="ms-2" />
									</Button>
									<Button
										className="px-3 me-3 "
										variant="outline-dark-bg"
										as={Link}
										to={`/admin/duplicate-invite?code=${selectedEventId}`}
									>
										Duplicate <IoDuplicateSharp className="ms-2" />
									</Button>
									{isAdmin && (
										<Button
											className="px-3  text-white"
											variant="red"
											onClick={() =>
												handleDelete(selectedEvent, selectedEventId)
											}
										>
											Delete <MdDelete className="ms-2" />
										</Button>
									)}
								</Col>
							</Row>

							<hr />
							<Row>
								<Col lg={12}>
									<Row>
										<Col xs={3}>
											<h3 className="fs-6 text-med-grey">Title</h3>
											<p className="text-black">{selectedEvent?.event}</p>
										</Col>
										<Col xs={3}>
											<h3 className="fs-6 text-med-grey">Code</h3>
											<p className="text-black">{selectedEvent?.code}</p>
										</Col>
										{/* <Col xs={3}>
											<h3 className="fs-6 text-med-grey">Participation Type</h3>
											<p className="text-black">
												{selectedEvent?.participationType}
											</p>
										</Col> */}
										<Col xs={3}>
											<h3 className="fs-6 text-med-grey">Venue</h3>
											<p className="text-black">{selectedEvent?.venue}</p>
										</Col>
									</Row>
									<Row className="mt-4">
										{" "}
										<Col xs={3}>
											<h3 className="fs-6 text-med-grey">Date</h3>
											<p className="text-black">{selectedEvent?.date}</p>
										</Col>
										<Col xs={3}>
											<h3 className="fs-6 text-med-grey">Country</h3>
											<p className="text-black">{selectedEvent?.country}</p>
										</Col>
										<Col xs={3}>
											<h3 className="fs-6 text-med-grey">Cancellation Date</h3>
											<p className="text-black">
												{new Date(
													selectedEvent?.eventCancellationDate
												).toLocaleDateString("en-GB")}
											</p>
										</Col>
										<Col xs={3}>
											<h3 className="fs-6 text-med-grey">Cancellation Fee</h3>
											<p className="text-black">
												{selectedEvent?.eventCancellationFee}
											</p>
										</Col>
									</Row>
									<Row className="mt-4">
										<Col xs={3}>
											<h3 className="fs-6 text-med-grey">Languages</h3>
											{selectEventLanguages.length > 0 && (
												<p className="text-black">
													{selectEventLanguages.map(
														(language, index, array) => (
															<>
																{language.value}
																{index !== array.length - 1 && ", "}
															</>
														)
													)}
												</p>
											)}
										</Col>
										<Col xs={3}>
											<h3 className="fs-6 text-med-grey">Forum Email</h3>
											<p
												style={{ wordBreak: "break-all" }}
												className="text-black"
											>
												{selectedEvent?.eventForumEmail}
											</p>
										</Col>
										<Col xs={3}>
											<h3 className="fs-6 text-med-grey">Redirect Url</h3>
											<p
												style={{ wordBreak: "break-all" }}
												className="text-black pe-2"
											>
												{selectedEvent?.eventRedirectUrl}
											</p>
										</Col>
										<Col xs={3}>
											<h3 className="fs-6 text-med-grey">Terms</h3>
											{selectEventLanguages.length > 0 && (
												<p className="text-black">
													{selectEventLanguages.map(
														(language, index, array) => (
															<>
																{language.terms.code}
																{index !== array.length - 1 && ", "}
																<br />
															</>
														)
													)}
												</p>
											)}
										</Col>
									</Row>
									<Row className="mt-4">
										{" "}
										<Col xs={true}>
											<h3 className="fs-6 text-med-grey">
												Thank You Message 1
											</h3>
											<p className="text-black">
												{selectedEvent?.thankYouMessage1}
											</p>
										</Col>
										<Col xs={true}>
											<h3 className="fs-6 text-med-grey">
												Thank You Message 2
											</h3>
											<p className="text-black">
												{selectedEvent?.thankYouMessage2}
											</p>
										</Col>
										<Col xs={3}>
											<h3 className="fs-6 text-med-grey">
												{" "}
												Cancellation Terms
											</h3>
											{selectEventLanguages.length > 0 && (
												<p className="text-black">
													{selectEventLanguages.map(
														(language, index, array) => (
															<>
																{language.termsCancel.code}
																{index !== array.length - 1 && ", "}
																<br />
															</>
														)
													)}
												</p>
											)}
										</Col>
									</Row>
								</Col>
							</Row>
							<Row>
								<Col>
									<h2 className="fs-4 mt-4">Delegate Manager(s) Details</h2>
									<hr />
								</Col>
							</Row>
							<Row className="mt-4">
								{" "}
								<Col xs={true}>
									<h3 className="fs-6 text-med-grey">Name</h3>
									<p className="text-black">{selectedEvent?.dm}</p>
								</Col>
								<Col xs={true}>
									<h3 className="fs-6 text-med-grey">Position</h3>
									<p className="text-black">{selectedEvent?.dmPosition}</p>
								</Col>
								<Col xs={true}>
									<h3 className="fs-6 text-med-grey">Telephone</h3>
									<p className="text-black">{selectedEvent?.dmTelephone}</p>
								</Col>
								<Col xs={true}>
									<h3 className="fs-6 text-med-grey">Email</h3>
									<p style={{ wordBreak: "break-all" }} className="text-black">
										{selectedEvent?.dmEmail}
									</p>
								</Col>
							</Row>
							{selectedEvent?.secondaryDm !== "" &&
								selectedEvent?.secondaryDm !== null && (
									<Row className="mt-4">
										{" "}
										<Col xs={true}>
											<h3 className="fs-6 text-med-grey">Name</h3>
											<p className="text-black">{selectedEvent?.secondaryDm}</p>
										</Col>
										<Col xs={true}>
											<h3 className="fs-6 text-med-grey">Position</h3>
											<p className="text-black">
												{selectedEvent?.secondaryDmPosition}
											</p>
										</Col>
										<Col xs={true}>
											<h3 className="fs-6 text-med-grey">Telephone</h3>
											<p className="text-black">
												{selectedEvent?.secondaryDmTelephone}
											</p>
										</Col>
										<Col xs={true}>
											<h3 className="fs-6 text-med-grey">Email</h3>
											<p className="text-black">
												{selectedEvent?.secondaryDmEmail}
											</p>
										</Col>
									</Row>
								)}
						</div>
					</div>
				</Modal.Body>
			</Modal>
			<Container className="px-4 pt-3 pb-5 pb-lg-7" fluid>
				<Row className="justify-content-end mb-4">
					<Col lg={2}>
						<AccountBox />
					</Col>
				</Row>
				<Row className="h-100 gx-5">
					<Col lg={1}>
						<LeftNavbar />
					</Col>
					<Col lg={11}>
						<div
							style={{ borderRadius: "10px", minHeight: "400px" }}
							className="w-100 bg-white"
						>
							<Row className="justify-content-between pb-3 pt-4 ps-4 ms-1 pe-6">
								<Col>
									<h2 className="fs-5">Events</h2>
								</Col>
								<Col className="text-end">
									<Filters
										columnFilters={filtering}
										setColumnFilters={setFiltering}
									/>
								</Col>
							</Row>

							<Table hover>
								{renderTableHeader()}
								{loadForm && <tbody>{renderTableBody()}</tbody>}
								{!loadForm && (
									<tbody>
										<tr className="text-center">
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
										</tr>
										<tr className="text-center">
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
										</tr>
										<tr className="text-center">
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
										</tr>
										<tr className="text-center">
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
										</tr>
										<tr className="text-center">
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
										</tr>
									</tbody>
								)}
							</Table>
							<Row>
								<Col className="pe-4" lg={12}>
									<Row className="py-4 align-items-center">
										<Col className="text-end" lg={11}>
											<p className="pb-0 mb-0 fw-bold">
												Page {table.getState().pagination.pageIndex + 1} of{" "}
												{table.getPageCount()}
											</p>
										</Col>
										<Col className="" lg={1}>
											<ButtonGroup aria-label="Basic example">
												<Button
													style={{ ountline: "none", border: "none" }}
													variant="outline-black"
													onClick={() => table.previousPage()}
													disabled={!table.getCanPreviousPage()}
													className="mx-0 ps-0"
												>
													<IoIosArrowBack className="fs-2" />
												</Button>
												<Button
													className="mx-0 ps-0 "
													style={{ ountline: "none", border: "none" }}
													variant="outline-black"
													onClick={() => table.nextPage()}
													disabled={!table.getCanNextPage()}
												>
													<IoIosArrowForward className="fs-2" />
												</Button>
											</ButtonGroup>
										</Col>
									</Row>
								</Col>
							</Row>
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	);
};
export default TaskTable;
